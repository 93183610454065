import { API_V4_ENTITIES } from 'constants/entities';
import CoreService from './CoreService';

const buildPath = (entity, uid, method = 'GET') => {
  const productEntities = [
    'multipack',
    'color',
    'category',
    'material',
    'pattern',
    'ingredient',
    'benefit',
    'size',
    'type',
    'claim',
    'system',
    'gtin',
  ];
  switch (entity) {
    case 'product':
      return 'parent_product';
    case 'retailer':
      return 'retailer_info';
    case 'available_sellers':
      return 'location/available_sellers';
    case 'available_stores':
      return `location/${uid}/available_stores`;
    case 'available_single_product_stores':
      return `location/${uid}/available_stores?is_single_product=True`;
    case 'stylists':
      return `location/${uid}/sellers`;
    case 'custom_artists':
      if (method === 'GET') {
        return `location/${uid}/custom_artists`;
      }
      return 'custom_artist';
    case 'custom_artists_details':
      return `custom_artist/${uid}`;
    case 'batch_retailer':
      return 'retailer_info/create_imported_sellers';
    case 'theme':
      return 'cover_photo';
    default:
      return productEntities.includes(entity) ? `product_${entity}` : entity;
  }
};

class EntityService extends CoreService {
  /* eslint-disable no-useless-constructor */
  constructor(endpoint) {
    super(endpoint);
  }

  fetch(entity, uid, params = {}) {
    let path = `/${buildPath(entity, uid)}`;
    if (API_V4_ENTITIES.includes(entity)) {
      path = `${this.baseUrl}/v4${path}`;
    }
    return this.api.get(path, {
      params,
    });
  }

  fetchById(entity, id) {
    if (entity === 'product_image') return this.api.get(`/sku/${id}/images`);
    return this.api.get(`/${buildPath(entity)}/${id}`);
  }

  create(entity, data) {
    return this.api.post(`/${buildPath(entity, '', 'POST')}`, data);
  }

  update(entity, id, data) {
    return this.api.patch(`/${buildPath(entity, '', 'PATCH')}/${id}`, data);
  }

  delete(entity, id) {
    return this.api.delete(`/${buildPath(entity, '', 'DELETE')}/${id}`);
  }

  assignTo(entityId, entityType, values) {
    return this.api.post(`/${entityType}/${entityId}/assign`, values);
  }

  fetchStats(statEntry, uid) {
    const entryId = uid ? `&entry_id=${uid}` : '';
    return this.api.get(`/entry?entry_type=${statEntry}${entryId}`);
  }

  fetchStatsSum(statEntry, uid) {
    const entryId = uid ? `&entry_id=${uid}` : '';
    return this.api.get(`/entry/views?entry_type=${statEntry}${entryId}`);
  }

  fetchRetailerStats(uid) {
    return this.api.get(`/retailer_info/${uid}/retailer_stats`);
  }

  getCartsSum() {
    return this.api.get('/cart/sum');
  }

  getCarts() {
    return this.api.get('/cart');
  }

  getProfileInfo(vanityUrl) {
    return this.api.get(`/profile_info/${vanityUrl}`);
  }

  generateLink(vanityUrl) {
    return this.api.post(`/profile_info/${vanityUrl}/generatelink`);
  }

  getBrandConfig() {
    return this.api.post('/brand/config');
  }

  setBrandConfig(payload) {
    return this.api.post('/brand/config', payload);
  }

  getReport(reportType) {
    return this.api.get(`/report/${reportType}`);
  }

  getUserProducts() {
    return this.api.get('/retailer_info/products');
  }

  getSonarCloudStats() {
    return this.api.get('/project_status');
  }

  getOwnerStats() {
    return this.api.get('/cart/owner_stats');
  }

  generateLocationLinks(vanity) {
    return this.api.post(`/location/${vanity}/generatelink`);
  }

  generateOwnerGroupPageQrCode(vanity) {
    return this.api.post(`/owner_group_page/${vanity}/generatelink`);
  }

  generateLookQrCode(shortId) {
    const path = `${this.baseUrl}/v2/store/${shortId}/generatelink`;

    return this.api.post(path);
  }

  getOwnerDashboardUrl() {
    return this.api.get('/embedded_stats/owner_dashboard');
  }

  getAdminDashboardUrl() {
    return this.api.get('/embedded_stats/admin_dashboard');
  }

  getRetailersForGroupPage(groupPageUid) {
    return this.api.get(`/owner_group_page/${groupPageUid}/available_retailers`);
  }

  getBundle(shortId) {
    const path = `${this.baseUrl}/v2/store/${shortId}`;

    return this.api.get(path);
  }

  getBundleTimeZones(uid) {
    const path = `${this.baseUrl}/v2/store/${uid}/available_timezones`;

    return this.api.get(path);
  }

  getBrandContentTimeZones() {
    return this.api.get('/brand_content/available_timezones');
  }

  getExcludedCategories() {
    return this.api.get('/brand/excluded_categories');
  }

  getLocationPages(locationUid) {
    return this.api.get(`/location_page_module/single/${locationUid}`);
  }

  publishPageModules(locationUid) {
    return this.api.post(`/location/${locationUid}/publish_page_modules`);
  }

  checkVanityUrlAvailability(entity, vanity_url) {
    return this.api.post(`/${entity}/available_vanity_url`, { vanity_url });
  }

  getLinkMetadata(link) {
    return this.api.post('/brand_content/link_metadata', { link });
  }

  getOwnedBrandContent() {
    return this.api.get('/brand_content/owned');
  }

  updateLocationPagePosition(uid, position) {
    return this.api.patch(`/location_page_module/update_position/${uid}`, { position });
  }
}

const instance = new EntityService('/v3');

export default instance;
